#home-surveys ion-header {
  position: fixed; }
  #home-surveys ion-header ion-toolbar {
    --ion-toolbar-background: transparent; }
    #home-surveys ion-header ion-toolbar ion-menu-button {
      color: var(--ion-color-light-contrast); }

#home-surveys ion-content {
  --background: linear-gradient(
      to top,
      rgba(227, 211, 177, 1) 0%,
      rgba(227, 211, 177, 1) 8%,
      rgba(255, 240, 207, 1) 36%,
      rgb(255, 249, 235) 100%
    );
  --padding-top: 40px; }

#home-surveys ion-content .survey-buttons {
  padding: 5% 0;
  height: 100%; }
  #home-surveys ion-content .survey-buttons ion-row {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  #home-surveys ion-content .survey-buttons ion-col {
    margin: 10px 15px;
    max-width: calc(50vw - 10px - 30px) !important;
    height: calc(50vw - 10px - 30px);
    --ion-grid-column-padding: 0;
    overflow: hidden;
    border-radius: 10px;
    -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12); }
  #home-surveys ion-content .survey-buttons ion-router-link {
    height: 100%;
    background: white;
    display: block;
    text-align: center;
    color: black; }
    #home-surveys ion-content .survey-buttons ion-router-link ion-icon {
      --ionicon-stroke-width: 24px;
      width: 80%;
      height: 40%;
      margin: 20% 10%; }
    #home-surveys ion-content .survey-buttons ion-router-link .survey-button-label {
      position: absolute;
      bottom: 10%;
      left: 0;
      width: 100%;
      font-size: 0.8em;
      font-weight: 800;
      padding: 0 10px; }
