#home-species .species-list {
  --ion-grid-padding: 0;
  background: #f7f7f7; }

#home-species .species-list-item {
  --ion-grid-column-padding: 2px;
  background: #fffdef; }
  #home-species .species-list-item div.species-label {
    height: 55vw;
    width: 100%;
    text-transform: capitalize;
    background-size: cover !important;
    background-position: center !important; }
    #home-species .species-list-item div.species-label div {
      width: 100%;
      color: #0f5758;
      border: 0;
      position: absolute;
      bottom: -1px;
      padding: 7px 33px 7px 15px;
      background: #fffdefd9;
      font-size: 15px;
      font-weight: 600; }
      #home-species .species-list-item div.species-label div .species-label-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        --ionicon-stroke-width: 40px; }
