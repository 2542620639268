.leaflet-container {
  height: calc(100%);
  width: 100vw;
  background-color: #5d5d5d; }
  .leaflet-container .leaflet-control .geolocate-btn {
    border-radius: 4px;
    background: white;
    width: 42px;
    height: 42px;
    margin-left: 1px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    text-align: center;
    padding: 0; }
    .leaflet-container .leaflet-control .geolocate-btn ion-icon {
      width: 21px;
      height: 21px;
      margin-top: 4px; }
  .leaflet-container .leaflet-control .geolocate-btn.spin ion-icon {
    -webkit-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite; }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  .leaflet-container .sr-only {
    display: none; }
