#species-profile {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0; }
  #species-profile ion-card-content {
    padding-top: 0;
    padding-bottom: 23px; }
  #species-profile ion-card-title {
    text-transform: capitalize; }
  #species-profile .species-profile-photo {
    height: 200px;
    background-size: cover !important;
    background-position: center !important; }
  #species-profile .species-profile-icon {
    position: absolute;
    top: 13px;
    right: 9px;
    color: white;
    --ionicon-stroke-width: 16px;
    z-index: 100; }
  #species-profile .species-profile-icon-shadow {
    position: absolute;
    top: 13px;
    right: 8px;
    color: #00000047;
    z-index: 100; }
  #species-profile .species-profile-label {
    font-weight: bold;
    border-left: 3px solid var(--ion-color-primary);
    padding-left: 5px;
    text-transform: uppercase;
    margin: 7px auto 15px; }
    #species-profile .species-profile-label:not(:first-child) {
      margin: 23px auto 15px; }
  #species-profile .swiper-pagination {
    position: relative;
    top: 3px; }
    #species-profile .swiper-pagination .swiper-pagination-bullet {
      opacity: 0.5;
      --bullet-background: grey; }
    #species-profile .swiper-pagination .swiper-pagination-bullet-active {
      opacity: 0.8;
      --bullet-background-active: var(--ion-color-primary); }
