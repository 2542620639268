ion-menu ion-content {
  --padding-top: 20px;
  --padding-bottom: 20px;
  --background: linear-gradient(
    to top,
    rgba(227, 211, 177, 1) 0%,
    rgba(227, 211, 177, 1) 8%,
    rgba(255, 240, 207, 1) 36%,
    rgb(255, 249, 235) 100%
  ); }

ion-menu ion-footer {
  background: #e3d3b1;
  border-top: #e3d3b1 1px solid; }
  ion-menu ion-footer div {
    text-align: center; }
  ion-menu ion-footer p {
    margin-top: 0;
    opacity: 0.7; }
  ion-menu ion-footer img {
    width: 40%; }

ion-alert .log-out-checkbox {
  --padding-start: 0;
  border-top: 1px solid var(--ion-color-light);
  border-bottom: 1px solid var(--ion-color-light); }

ion-menu ion-item,
ion-menu ion-list {
  background: none !important;
  --background: none !important; }

/* Remove background transitions for switching themes */
ion-menu ion-item {
  --transition: none; }

ion-item.selected {
  --color: var(--ion-color-primary); }

ion-menu ion-list-header {
  margin-bottom: 20px; }

ion-menu ion-icon {
  color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 1);
  --ionicon-stroke-width: 26px; }

ion-menu ion-item.selected ion-icon {
  color: var(--ion-color-primary); }

ion-menu.md ion-list {
  padding: 20px 0; }

ion-menu.md ion-list-header {
  padding-left: 18px;
  padding-right: 18px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 450; }

ion-menu.md ion-item {
  --padding-start: 18px;
  margin-right: 10px;
  border-radius: 0 50px 50px 0;
  font-weight: 500; }

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14); }

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary); }

ion-menu.ios ion-list-header {
  padding-left: 16px;
  padding-right: 16px; }

ion-menu.ios ion-list {
  padding: 20px 0 0; }

ion-menu.ios ion-item {
  --padding-start: 16px;
  --min-height: 50px; }

ion-menu.ios ion-item ion-icon {
  font-size: 24px; }
