.location-photo .no-image,
.location-photo img {
  max-height: 20vh;
  min-height: 20vh;
  background: gray;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 0% 66%;
     object-position: 0% 66%; }

.location-photo .no-image {
  text-align: center;
  padding: 10%;
  color: rgba(255, 255, 255, 0.65); }

.location-photo .photo-zoomin-icon {
  position: absolute;
  top: 13px;
  right: 9px;
  color: white;
  --ionicon-stroke-width: 16px;
  z-index: 100; }

.location-photo .photo-zoomin-icon-shadow {
  position: absolute;
  top: 13px;
  right: 8px;
  color: #00000047;
  z-index: 100; }
