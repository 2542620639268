#welcome-page ion-header {
  position: absolute; }
  #welcome-page ion-header ion-toolbar {
    --background: transparent;
    --border-color: transparent; }

#welcome-page ion-slides {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white; }
  #welcome-page ion-slides ion-slide .message {
    position: relative;
    width: 78%;
    top: 23%; }
    #welcome-page ion-slides ion-slide .message .icon-size {
      font-size: 40px;
      stroke-width: 2; }
    #welcome-page ion-slides ion-slide .message .add-icon {
      background: var(--ion-color-primary);
      padding: 5px;
      border-radius: 10px;
      color: white;
      margin: 0 5px 0 8px;
      margin-bottom: -7px; }
  #welcome-page ion-slides ion-slide.first {
    background: -webkit-gradient(linear, left bottom, left top, from(#e3d3b1), color-stop(8%, #f5e6c6), color-stop(36%, #fff8eb), to(#fff8eb));
    background: linear-gradient(to top, #e3d3b1 0%, #f5e6c6 8%, #fff8eb 36%, #fff8eb 100%); }
    #welcome-page ion-slides ion-slide.first .slide-header {
      background-size: cover;
      height: 55%;
      width: 100%;
      position: absolute;
      top: 0;
      /** For mobile devices **/ }
      #welcome-page ion-slides ion-slide.first .slide-header .custom-shape-divider-bottom-1593438501 {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
      #welcome-page ion-slides ion-slide.first .slide-header .custom-shape-divider-bottom-1593438501 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 65px; }
      #welcome-page ion-slides ion-slide.first .slide-header .custom-shape-divider-bottom-1593438501 .shape-fill {
        fill: #fff8eb; }
      #welcome-page ion-slides ion-slide.first .slide-header .custom-shape-divider-bottom-1593438501 svg {
        width: calc(152% + 1.3px);
        height: 65px; }
    #welcome-page ion-slides ion-slide.first .slide-header {
      background-image: url("/images/first.jpg"); }
  #welcome-page ion-slides ion-slide.second {
    background: -webkit-gradient(linear, left bottom, left top, from(#e3d3b1), color-stop(8%, #f5e6c6), color-stop(36%, #fff8eb), to(#fff8eb));
    background: linear-gradient(to top, #e3d3b1 0%, #f5e6c6 8%, #fff8eb 36%, #fff8eb 100%); }
    #welcome-page ion-slides ion-slide.second .slide-header {
      background-size: cover;
      height: 55%;
      width: 100%;
      position: absolute;
      top: 0;
      /** For mobile devices **/ }
      #welcome-page ion-slides ion-slide.second .slide-header .custom-shape-divider-bottom-1593438501 {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
      #welcome-page ion-slides ion-slide.second .slide-header .custom-shape-divider-bottom-1593438501 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 65px; }
      #welcome-page ion-slides ion-slide.second .slide-header .custom-shape-divider-bottom-1593438501 .shape-fill {
        fill: #fff8eb; }
      #welcome-page ion-slides ion-slide.second .slide-header .custom-shape-divider-bottom-1593438501 svg {
        width: calc(152% + 1.3px);
        height: 65px; }
    #welcome-page ion-slides ion-slide.second .slide-header {
      background-image: url("/images/second.jpg"); }
  #welcome-page ion-slides ion-slide.third {
    background: -webkit-gradient(linear, left bottom, left top, from(#e3d3b1), color-stop(8%, #f5e6c6), color-stop(36%, #fff8eb), to(#fff8eb));
    background: linear-gradient(to top, #e3d3b1 0%, #f5e6c6 8%, #fff8eb 36%, #fff8eb 100%); }
    #welcome-page ion-slides ion-slide.third .slide-header {
      background-size: cover;
      height: 55%;
      width: 100%;
      position: absolute;
      top: 0;
      /** For mobile devices **/ }
      #welcome-page ion-slides ion-slide.third .slide-header .custom-shape-divider-bottom-1593438501 {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
      #welcome-page ion-slides ion-slide.third .slide-header .custom-shape-divider-bottom-1593438501 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 65px; }
      #welcome-page ion-slides ion-slide.third .slide-header .custom-shape-divider-bottom-1593438501 .shape-fill {
        fill: #fff8eb; }
      #welcome-page ion-slides ion-slide.third .slide-header .custom-shape-divider-bottom-1593438501 svg {
        width: calc(152% + 1.3px);
        height: 65px; }
    #welcome-page ion-slides ion-slide.third .slide-header {
      background-image: url("/images/third.jpg"); }
  #welcome-page ion-slides ion-slide.fourth {
    background: -webkit-gradient(linear, left bottom, left top, from(#e3d3b1), color-stop(8%, #f5e6c6), color-stop(36%, #fff8eb), to(#fff8eb));
    background: linear-gradient(to top, #e3d3b1 0%, #f5e6c6 8%, #fff8eb 36%, #fff8eb 100%); }
    #welcome-page ion-slides ion-slide.fourth .slide-header {
      background-size: cover;
      height: 55%;
      width: 100%;
      position: absolute;
      top: 0;
      /** For mobile devices **/ }
      #welcome-page ion-slides ion-slide.fourth .slide-header .custom-shape-divider-bottom-1593438501 {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
      #welcome-page ion-slides ion-slide.fourth .slide-header .custom-shape-divider-bottom-1593438501 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 65px; }
      #welcome-page ion-slides ion-slide.fourth .slide-header .custom-shape-divider-bottom-1593438501 .shape-fill {
        fill: #fff8eb; }
      #welcome-page ion-slides ion-slide.fourth .slide-header .custom-shape-divider-bottom-1593438501 svg {
        width: calc(152% + 1.3px);
        height: 65px; }
    #welcome-page ion-slides ion-slide.fourth .slide-header {
      background-image: url("/images/fourth.jpg"); }
  #welcome-page ion-slides ion-slide.fifth {
    background: -webkit-gradient(linear, left bottom, left top, from(#e3d3b1), color-stop(8%, #f5e6c6), color-stop(36%, #fff8eb), to(#fff8eb));
    background: linear-gradient(to top, #e3d3b1 0%, #f5e6c6 8%, #fff8eb 36%, #fff8eb 100%); }
    #welcome-page ion-slides ion-slide.fifth .slide-header {
      background-size: cover;
      height: 55%;
      width: 100%;
      position: absolute;
      top: 0;
      /** For mobile devices **/ }
      #welcome-page ion-slides ion-slide.fifth .slide-header .custom-shape-divider-bottom-1593438501 {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
      #welcome-page ion-slides ion-slide.fifth .slide-header .custom-shape-divider-bottom-1593438501 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 65px; }
      #welcome-page ion-slides ion-slide.fifth .slide-header .custom-shape-divider-bottom-1593438501 .shape-fill {
        fill: #fff8eb; }
      #welcome-page ion-slides ion-slide.fifth .slide-header .custom-shape-divider-bottom-1593438501 svg {
        width: calc(152% + 1.3px);
        height: 65px; }
    #welcome-page ion-slides ion-slide.fifth .slide-header {
      background-image: url("/images/fifth.jpg"); }
      #welcome-page ion-slides ion-slide.fifth .slide-header ion-icon {
        background: var(--ion-color-primary);
        padding: 5px;
        border-radius: 10px;
        color: white;
        margin: 0 5px 0 8px;
        margin-bottom: -7px; }
  #welcome-page ion-slides p,
  #welcome-page ion-slides h2 {
    margin-top: 1.1rem;
    text-align: center; }
  #welcome-page ion-slides h2 {
    text-align: center;
    font-size: 22px; }
  #welcome-page ion-slides b {
    font-weight: 500; }
  #welcome-page ion-slides p {
    font-size: 17px;
    line-height: 1.5;
    font-weight: 200;
    margin-bottom: 9px;
    text-align: center; }
    #welcome-page ion-slides p b {
      color: var(--ion-text-color, #000000); }
  #welcome-page ion-slides .swiper-pagination-bullets {
    bottom: 3%; }
    #welcome-page ion-slides .swiper-pagination-bullets .swiper-pagination-bullet {
      opacity: 1; }
    #welcome-page ion-slides .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 0.8;
      --bullet-background-active: #000; }
