#survey-dunes-profile-transect-edit ion-content #add {
  margin: 30px 0;
  margin-left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

#survey-dunes-profile-transect-edit ion-content .survey-point-item {
  text-transform: capitalize; }

#survey-dunes-profile-transect-edit ion-content .dunes-profile-label {
  text-transform: none;
  font-size: 0.9rem;
  margin: 0; }
  #survey-dunes-profile-transect-edit ion-content .dunes-profile-label ion-icon {
    margin-bottom: -2px; }
  #survey-dunes-profile-transect-edit ion-content .dunes-profile-label .dunes-profile-values {
    padding-top: 2px; }

#survey-dunes-profile-transect-edit ion-content .show-type-icon-downslope {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }
