#img-picker-array {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 80px;
  background: #ffffff;
  margin: 0;
  padding: 0; }
  #img-picker-array .img-picker {
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    min-height: 80px;
    padding: 0;
    border-right: 1px solid var(--ion-color-light-shade);
    color: var(--ion-color-dark); }
    #img-picker-array .img-picker ion-button {
      margin: 0;
      margin-left: 54%;
      height: 100%;
      width: 80px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
      #img-picker-array .img-picker ion-button ion-icon {
        width: 65%;
        height: 65%; }
    #img-picker-array .img-picker .non-cordova-img-picker {
      width: 80px; }
      #img-picker-array .img-picker .non-cordova-img-picker .non-cordova-img-picker-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-16px, -16px);
                transform: translate(-16px, -16px);
        color: var(--ion-color-primary); }
      #img-picker-array .img-picker .non-cordova-img-picker > input {
        position: absolute;
        opacity: 0;
        width: 80px;
        margin-top: 0 !important;
        height: 80px !important; }
  #img-picker-array #img-array {
    display: table-cell;
    width: auto;
    white-space: nowrap;
    overflow: overlay;
    overflow-y: hidden;
    width: 100%; }
    #img-picker-array #img-array .img {
      display: inline-block;
      position: relative; }
      #img-picker-array #img-array .img ion-button.delete {
        position: absolute;
        height: 20px;
        width: 100%;
        background-color: #8080807d;
        margin: 0; }
        #img-picker-array #img-array .img ion-button.delete ion-icon {
          color: white; }
      #img-picker-array #img-array .img img {
        height: 80px; }
    #img-picker-array #img-array .empty {
      width: calc(100% - 80px);
      text-align: center;
      position: absolute;
      color: var(--ion-color-dark-tint);
      font-size: 14px;
      padding: 0 15px;
      margin-top: 30px; }

ion-item#edit-footer {
  --padding-start: 0; }

ion-footer#edit-footer > div:not(.pswp) {
  margin: 0 -5px;
  border-top: 1px solid var(--ion-color-light-shade);
  -webkit-box-shadow: 0px -1px 6px 0px #b5b5b5;
          box-shadow: 0px -1px 6px 0px #b5b5b5; }

.ios .pswp__top-bar {
  margin-top: 30px; }
