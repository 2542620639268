#habitat-profile {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0; }
  #habitat-profile ion-card-content {
    padding-top: 0;
    padding-bottom: 0; }
  #habitat-profile .habitat-profile-icon {
    position: absolute;
    top: 13px;
    right: 9px;
    color: white;
    --ionicon-stroke-width: 16px;
    z-index: 100; }
  #habitat-profile .habitat-profile-icon-shadow {
    position: absolute;
    top: 13px;
    right: 8px;
    color: #00000047;
    z-index: 100; }
  #habitat-profile .habitat-label {
    font-weight: bold;
    border-left: 3px solid var(--ion-color-primary);
    padding-left: 5px;
    text-transform: uppercase;
    margin: 10px auto 15px; }
  #habitat-profile .swiper-pagination {
    position: relative;
    top: 3px; }
    #habitat-profile .swiper-pagination .swiper-pagination-bullet {
      opacity: 0.5;
      --bullet-background: grey; }
    #habitat-profile .swiper-pagination .swiper-pagination-bullet-active {
      opacity: 0.8;
      --bullet-background-active: var(--ion-color-primary); }
  #habitat-profile .habitat-profile-photo {
    height: 200px;
    background-size: cover !important; }
