#home-habitats .habitat-list {
  --ion-grid-padding: 0; }
  #home-habitats .habitat-list ion-row {
    background: #fffdef; }

#home-habitats .habitat-list-item {
  --ion-grid-column-padding: 0;
  margin-bottom: 30px; }
  #home-habitats .habitat-list-item:last-child {
    margin-bottom: 0px; }
  #home-habitats .habitat-list-item div.label {
    height: 60vw;
    width: 100%;
    background-size: cover; }
    #home-habitats .habitat-list-item div.label div {
      width: 100%;
      color: #0f5758;
      border: 0;
      position: absolute;
      top: 0px;
      padding: 7px 15px;
      background: #fffdefd9;
      font-size: 15px;
      font-weight: 600; }
      #home-habitats .habitat-list-item div.label div .habitat-label-icon {
        position: absolute;
        right: 15px;
        --ionicon-stroke-width: 40px; }
