:root {
  --ion-color-primary: #00a0a4;
  --ion-color-primary-rgb: 0, 160, 164;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #008d90;
  --ion-color-primary-tint: #1aaaad;
  --ion-color-secondary: #e3d3b1;
  --ion-color-secondary-rgb: 227, 211, 177;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #c8ba9c;
  --ion-color-secondary-tint: #e6d7b9;
  --ion-color-tertiary: #875caf;
  --ion-color-tertiary-rgb: 135, 92, 175;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #77519a;
  --ion-color-tertiary-tint: #936cb7;
  --ion-color-success: #00bd1a;
  --ion-color-success-rgb: 0, 189, 26;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #00a617;
  --ion-color-success-tint: #1ac431;
  --ion-color-warning: #f8d616;
  --ion-color-warning-rgb: 248, 214, 22;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dabc13;
  --ion-color-warning-tint: #f9da2d;
  --ion-color-danger: #ca437a;
  --ion-color-danger-rgb: 202, 67, 122;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b23b6b;
  --ion-color-danger-tint: #cf5687;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9; }

.ios,
.md {
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: #fff;
  --ion-toolbar-color-activated: #fff; }

.info-message,
.success-message,
.warning-message {
  font-size: 13px;
  font-weight: normal;
  border-radius: 0;
  padding: 3px 15px 3px 42px;
  line-height: 1.4em;
  text-shadow: none;
  text-align: center; }

.info-message p,
.success-message p,
.warning-message p {
  margin: 13px 22px 13px 0;
  color: var(--ion-color-light); }
  .info-message p a,
  .success-message p a,
  .warning-message p a {
    color: var(--ion-color-light);
    font-weight: bold; }
  .info-message p ion-button,
  .success-message p ion-button,
  .warning-message p ion-button {
    display: block; }

.info-message {
  color: var(--ion-color-light);
  background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M7%2C0C3.134%2C0%2C0%2C3.134%2C0%2C7s3.134%2C7%2C7%2C7s7-3.134%2C7-7S10.866%2C0%2C7%2C0z%20M7%2C2c0.552%2C0%2C1%2C0.447%2C1%2C1S7.552%2C4%2C7%2C4S6%2C3.553%2C6%2C3%20S6.448%2C2%2C7%2C2z%20M9%2C11H5v-1h1V6H5V5h3v5h1V11z%22%2F%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3C%2Fsvg%3E") 10px center/22px auto no-repeat #008eec; }

.warning-message {
  color: var(--ion-color-light);
  background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20style%3D%22fill%3A%23FFFFFF%3B%22%20d%3D%22M7%2C0L0%2C12h14L7%2C0z%20M7%2C11c-0.553%2C0-1-0.447-1-1s0.447-1%2C1-1c0.553%2C0%2C1%2C0.447%2C1%2C1S7.553%2C11%2C7%2C11z%20M7%2C8%20C6.447%2C8%2C6%2C7.553%2C6%2C7V5c0-0.553%2C0.447-1%2C1-1c0.553%2C0%2C1%2C0.447%2C1%2C1v2C8%2C7.553%2C7.553%2C8%2C7%2C8z%22%2F%3E%3C%2Fsvg%3E") 10px center/22px auto no-repeat var(--ion-color-warning-tint); }

.success-message {
  color: var(--ion-color-light);
  background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20style%3D%22fill%3A%23FFFFFF%3B%22%20points%3D%2214%2C4%2011%2C1%205.003%2C6.997%203%2C5%200%2C8%204.966%2C13%204.983%2C12.982%205%2C13%20%22%2F%3E%3C%2Fsvg%3E") 10px center/22px auto no-repeat var(--ion-color-success-tint); }

ion-button[type='submit'] {
  width: 40%;
  margin: 20px auto 40px; }

.empty-page-message {
  position: fixed;
  color: var(--ion-color-dark-tint);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 50%;
  width: 70%;
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  --background: #ffeb95; }
  .empty-page-message p {
    width: 100%;
    text-align: center; }

ion-textarea {
  --padding-start: 10px !important; }

.menu-attr-item-long-value ion-label[slot='end'] {
  font-size: 0.8em; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield; }

html.ios ion-action-sheet ion-icon.action-sheet-icon {
  position: absolute;
  left: 0; }

html.ios ion-alert * {
  --ion-background-color: transparent; }

.pretty-button {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 50%;
  width: 88%;
  text-align: center;
  margin-bottom: 10px;
  --inner-border-width: 0;
  -webkit-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.18), 0 6px 12px 0 rgba(0, 0, 0, 0.17), 0 1px 1px 0 rgba(0, 0, 0, 0.17);
          box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.18), 0 6px 12px 0 rgba(0, 0, 0, 0.17), 0 1px 1px 0 rgba(0, 0, 0, 0.17);
  border-radius: 5px;
  --background: #00a0a4;
  --color: white;
  --color-activated: white; }
  .pretty-button p {
    width: 100%;
    text-align: center; }

ion-item-divider {
  background-color: #f9f9f9d9;
  color: var(--ion-color-primary);
  font-size: 15px;
  font-weight: 600; }

.ios ion-item-divider {
  border-bottom: 1px solid #c8c7cc; }

figcaption {
  font-size: 0.7em; }

.info-background-message span {
  font-weight: 400; }

ion-app[lang='cy'] ion-header ion-title {
  text-transform: none !important; }

ion-app[lang='cy'] ion-col div.species-label {
  text-transform: none !important; }

ion-app[lang='cy'] ion-card-header ion-card-title {
  text-transform: none !important; }

html.ios.plt-hybrid #status-bar-background {
  background: #094d4e;
  width: 100vw;
  height: env(safe-area-inset-top);
  position: fixed;
  top: 0;
  z-index: 1000; }

ion-segment-button ion-label {
  padding: 0 15px; }

.survey-point-item.completed ion-label:not(:last-child) {
  font-weight: bold; }

.survey-point-item.completed ion-icon {
  color: var(--ion-color-primary); }

.survey-point-item ion-label:not(:last-child) {
  white-space: normal; }
