#user-login ion-toolbar {
  position: fixed;
  --ion-toolbar-background: transparent; }
  #user-login ion-toolbar ion-back-button {
    color: var(--ion-color-light-contrast); }

#user-login ion-content {
  --background: linear-gradient(
      to top,
      rgba(227, 211, 177, 1) 0%,
      rgba(227, 211, 177, 1) 8%,
      rgba(255, 240, 207, 1) 36%,
      rgb(255, 249, 235) 100%
    );
  --padding-top: 40px; }

#user-login ion-list,
#user-login ion-item {
  --background: transparent;
  background: transparent; }

#user-login ion-content ion-icon {
  color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 1); }

#user-login ion-content h1 {
  text-align: center;
  font-size: 1.4em;
  font-weight: 500; }

#user-login ion-content h2 {
  text-align: center;
  font-size: 1em;
  font-weight: 400;
  opacity: 0.7;
  margin-bottom: 70px; }

#user-login ion-content form ion-router-link.password-forgot-button {
  display: block;
  padding: 10px;
  text-align: right;
  font-size: 0.85em;
  color: var(--ion-color-dark); }

#user-login ion-content ion-button[type='submit'] {
  margin-bottom: 60px; }
