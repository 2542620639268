.ion-page#language-select-required {
  background: url("/images/language-page-background.jpg") no-repeat center center/cover; }
  .ion-page#language-select-required .header {
    padding: 50px; }
    .ion-page#language-select-required .header * {
      width: 100%;
      font-weight: 300;
      text-align: center; }
    .ion-page#language-select-required .header ion-icon {
      font-size: 80px;
      --ionicon-stroke-width: 8px; }
  .ion-page#language-select-required .language-select-list,
  .ion-page#language-select-required ion-content {
    --background: transparent;
    background-color: transparent; }
  .ion-page#language-select-required .pretty-button-language {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 50%;
    width: 88%;
    text-align: center;
    margin-bottom: 10px;
    --inner-border-width: 0;
    -webkit-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.18), 0 6px 12px 0 rgba(0, 0, 0, 0.17), 0 1px 1px 0 rgba(0, 0, 0, 0.17);
            box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.18), 0 6px 12px 0 rgba(0, 0, 0, 0.17), 0 1px 1px 0 rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    --background: #ffffffed; }
    .ion-page#language-select-required .pretty-button-language p {
      width: 100%;
      text-align: center; }
