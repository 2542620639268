.survey-list-item ion-item {
  --padding-start: 0; }

.survey-list-item ion-avatar {
  width: 50px;
  height: 50px;
  background: aliceblue;
  margin: 0 10px; }
  .survey-list-item ion-avatar ion-icon {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    --ionicon-stroke-width: 20px; }
