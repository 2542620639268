ion-fab-button.home-fab {
  --border-radius: 22px;
  position: fixed;
  bottom: calc(10px + env(safe-area-inset-bottom));
  z-index: 11;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

ion-tab-bar {
  --background: #fffdef; }
  ion-tab-bar ion-tab-button {
    --color: black; }
    ion-tab-bar ion-tab-button ion-icon.ios {
      --ionicon-stroke-width: 20px; }
