#home-info ion-header {
  position: fixed; }

#home-info ion-content .app-logo {
  position: absolute;
  z-index: 10000;
  width: 67%;
  max-width: 250px;
  left: 50%;
  top: 7%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

#home-info ion-content {
  --background: linear-gradient(
      to top,
      rgba(227, 211, 177, 1) 0%,
      rgb(245, 230, 198) 8%,
      rgb(255, 248, 235) 32%,
      rgb(255, 248, 235) 100%
    ); }

#home-info .pretty-button-empty {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 50%;
  width: 88%;
  text-align: center;
  margin-bottom: 10px;
  --inner-border-width: 0;
  -webkit-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.18), 0 6px 12px 0 rgba(0, 0, 0, 0.17), 0 1px 1px 0 rgba(0, 0, 0, 0.17);
          box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.18), 0 6px 12px 0 rgba(0, 0, 0, 0.17), 0 1px 1px 0 rgba(0, 0, 0, 0.17);
  border-radius: 5px;
  --background: #ffffff4f;
  --color: rgb(173 0 0 / 82%);
  --color-activated: rgb(173 0 0 / 82%);
  border: 1px solid #ad0000;
  font-weight: 500;
  letter-spacing: 1px; }
  #home-info .pretty-button-empty p {
    width: 100%;
    text-align: center; }

#home-info .pretty-button-selected {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 50%;
  width: 88%;
  text-align: center;
  margin-bottom: 10px;
  --inner-border-width: 0;
  -webkit-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.18), 0 6px 12px 0 rgba(0, 0, 0, 0.17), 0 1px 1px 0 rgba(0, 0, 0, 0.17);
          box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.18), 0 6px 12px 0 rgba(0, 0, 0, 0.17), 0 1px 1px 0 rgba(0, 0, 0, 0.17);
  border-radius: 5px;
  --background: #ffffff4f;
  --color: #00a0a4ba;
  --color-activated: #00a0a4ba;
  border: 1px solid #00a0a4ba;
  font-weight: 500;
  letter-spacing: 1px; }
  #home-info .pretty-button-selected p {
    width: 100%;
    text-align: center; }

#home-info .slide-header {
  height: 64%;
  width: 100%;
  position: absolute;
  top: 0;
  /** For mobile devices **/ }
  #home-info .slide-header .app-home-background {
    width: 100%;
    height: 100%;
    overflow: hidden; }
  #home-info .slide-header .custom-shape-divider-bottom-1593438501 {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  #home-info .slide-header .custom-shape-divider-bottom-1593438501 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 65px; }
  #home-info .slide-header .custom-shape-divider-bottom-1593438501 .shape-fill {
    fill: #fff8eb; }
  #home-info .slide-header .custom-shape-divider-bottom-1593438501 svg {
    width: calc(152% + 1.3px);
    height: 65px; }

#home-info ion-content ion-item-group {
  position: absolute;
  top: 92%;
  -webkit-transform: translateY(-90%);
          transform: translateY(-90%);
  width: 100%;
  margin-bottom: 10px; }
  #home-info ion-content ion-item-group .info-text {
    width: 91%;
    text-align: center;
    margin-top: 55px;
    --background: none;
    margin: 14px auto 0px auto;
    border-radius: 5px;
    color: #000000;
    opacity: 0.7; }
  #home-info ion-content ion-item-group ion-label {
    font-size: 0.9em;
    padding: 4px 0px;
    margin: 0; }

#home-info ion-toolbar {
  --ion-toolbar-background: transparent; }
  #home-info ion-toolbar ion-menu-button {
    color: var(--ion-color-light-contrast); }

#home-info ion-icon {
  background: var(--ion-color-primary);
  padding: 5px;
  border-radius: 10px;
  color: white;
  margin: 0 5px 0 8px;
  margin-bottom: -7px; }
