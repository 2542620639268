#user-reset ion-toolbar {
  position: fixed;
  --ion-toolbar-background: transparent; }
  #user-reset ion-toolbar ion-back-button {
    color: var(--ion-color-light-contrast); }

#user-reset ion-content {
  --background: linear-gradient(
      to top,
      rgba(227, 211, 177, 1) 0%,
      rgba(227, 211, 177, 1) 8%,
      rgba(255, 240, 207, 1) 36%,
      rgb(255, 249, 235) 100%
    );
  --padding-top: 40px; }

#user-reset ion-list,
#user-reset ion-item {
  --background: transparent;
  background: transparent; }

#user-reset ion-content ion-icon {
  color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 1); }

#user-reset ion-content h2 {
  text-align: center;
  font-size: 1em;
  font-weight: 400;
  opacity: 0.7;
  padding: 20px 20px 40px; }

#user-reset ion-content ion-button[type='submit'] {
  margin-bottom: 60px; }
